export const environment = {
  production: false,
  api: 'https://vip-admin-api-dev.linkroom.com',
  apiLinkroom: 'https://admindev-api.linkroom.com',
  clientOsu: 'https://fxp-dev.linkroom.com',
  experience: 'https://linkroomv3dev.web.app',
  firebase: {
    projectId: 'linkroom-v2-dev',
    appId: '1:383066649579:web:19c4ead8fa9b62525a1fae',
    storageBucket: 'linkroom-v2-dev.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyAmHvWxoIoLv41qJVXeIGlo6Y-Jbn_K6DI',
    authDomain: 'linkroom-v2-dev.firebaseapp.com',
    messagingSenderId: '383066649579',
    measurementId: 'G-5HW1Y5YHWW'
  },
  imagekit: {
    publicKey: 'public_HaXtq7gDdr1WTjhEhsMpoKTpp+8=',
    urlEndpoint: 'https://ik.imagekit.io/b152q4ah5/linkroom-dev'
  },
  stripe: {
    pk: 'pk_test_51MwSWlG8SGFDayonaMDGntqMTtH9Cupr3Z59yLLIhPkYzJjiJrW1hseyAIr6VJMAI8UxPzxcrF8JXumfBnj4ufeN00e9mxM41Y'
  },
  AGORA_APP_ID: '6640356cca2b4467bd60de894ce93c0e',
  PHOTON_APP_ID: '08d616c9-fae9-4f7d-9db8-a8a0824b1e30'
};
