export enum EventUserStatus {
  PENDING = 0,
  ONGOING = 1,
  DONE = 2,
  REMOVED = 3
}

export enum ExtendedTimeStatus {
  PENDING = 0,
  INPROGRESS = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  DONE = 4,
}
