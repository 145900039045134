import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { collection, collectionData, collectionGroup, Firestore, onSnapshot } from '@angular/fire/firestore';

import { map } from 'rxjs';

import { TimeAdded, TimeAddedViewModel } from '../models/calls.model';
import { VipEventViewModel } from '../models/event.model';
import { Guest, GuestViewModel } from '../models/guest.model';
import { OrganizationViewModel } from '../models/organization.model';
import { LandingPicturesViewModel, ProfileViewModel } from '../models/profile.model';
import { VipViewModel } from '../models/vip.model';
import { Result } from 'src/app/shared/models/result.model';
import {
  EVENT,
  PROFILE,
  RESELLER,
  USER,
  VIP,
  VIP_PROFILE
} from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  private firestore: Firestore = inject(Firestore);

  constructor(private http: HttpClient) {}

  getOrganizationByVipDomain(vipDomain: string) {
    return this.http.get<Result<OrganizationViewModel>>(
      `${environment.api}/public/vip-domain?vipDomain=${encodeURIComponent(vipDomain)}`
    );
  }

  updatePaymentIntent(guestId: string | null, paymentIntent: string) {
    return this.http.put<Result<string>>(
      `${environment.api}/public${PROFILE.endpoint}/guests/payment-intent/${guestId}/${paymentIntent}`,
      {}
    );
  }

  getPublicUser(id: string | null) {
    return this.http.get<Result<VipViewModel>>(`${environment.api}/public${USER.endpoint}/${id}`);
  }

  getProfile(id: string | null) {
    return this.http.get<Result<ProfileViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/${id}`
    );
  }

  getVipFromProfile(id: string | null) {
    return this.http.get<Result<string>>(
      `${environment.api}/public${PROFILE.endpoint}/vipId/${id}`
    );
  }

  getVipDomainOrganization(vipDomain: string) {
    return this.http.get<Result<OrganizationViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/vipDomain/${vipDomain}`
    );
  }

  getProfileWithUrl(profileUrl: string | null) {
    return this.http.get<Result<ProfileViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/url/${profileUrl}`
    );
  }

  getGuest(id: string | null) {
    return this.http.get<Result<GuestViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/guests/${id}`
    );
  }

  updateGuest(id: string | null, guest: Guest) {
    return this.http.put<Result<string>>(
      `${environment.api}/public${PROFILE.endpoint}/guests/${id}`,
      guest
    );
  }

  getProfiles(orgId: string | null, guestEmail?: string | null) {
    return this.http.get<Result<ProfileViewModel[]>>(
      `${environment.api}/public${PROFILE.endpoint}/organizations/${orgId}/${guestEmail}`
    );
  }

  getProfileEvent(id: string | null) {
    return this.http.get<Result<VipEventViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/events/${id}`
    );
  }

  addGuest(eventId: string | null, guest: Guest) {
    return this.http.post<Result<string>>(
      `${environment.api}/public${PROFILE.endpoint}/guests/${eventId}`,
      guest
    );
  }

  subscribeToVip(profileId: string | null, profileName: string | null, guest: Guest) {
    return this.http.post(
      `${environment.api}/public${PROFILE.endpoint}/subscriptions/${profileId}/${profileName}`,
      guest
    );
  }

  subscribeToVipTwo(
    profileId: string | null,
    profileName: string | null,
    profileId2: string | null,
    profileName2: string | null,
    guest: Guest
  ) {
    return this.http.post(
      `${environment.api}/public${PROFILE.endpoint}/subscriptions-two/${profileId}/${profileName}/${profileId2}/${profileName2}`,
      guest
    );
  }

  subscribeToMoreVips(profiles: ProfileViewModel[], guest: Guest) {
    return this.http.post(`${environment.api}/public${PROFILE.endpoint}/subscription-more-vips`, {
      guest,
      profiles
    });
  }

  unSubscribeToVip(profileId: string | null, subscriberId: string | null) {
    return this.http.delete(
      `${environment.api}/public${PROFILE.endpoint}/subscriptions/${profileId}/${subscriberId}`
    );
  }

  updatePhotoGuest(guestId: string | null, file: { file: string }) {
    return this.http.put(
      `${environment.api}/public${PROFILE.endpoint}/guests/photo/${guestId}`,
      file
    );
  }

  activateAccount(id: string | null, auth: string) {
    return this.http.put<Result<VipViewModel>>(
      `${environment.api}/public${USER.endpoint}/${id}/${auth}`,
      {}
    );
  }

  updatePaymentActive(
    guestId: string | null,
    paymentIntent: string | null,
    paymentActive: boolean
  ) {
    return this.http.put<Result<VipViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/guests/active-payment/${guestId}/${paymentIntent}`,
      { paymentActive }
    );
  }

  addTime(callId: string | null, timeAdded: TimeAdded) {
    return this.http.post<Result<string>>(
      `${environment.api}/public${PROFILE.endpoint}/addedTimes/${callId}`,
      timeAdded
    );
  }

  getTime(id: string) {
    return this.http.get<Result<TimeAddedViewModel>>(
      `${environment.api}/public${PROFILE.endpoint}/addedTimes/${id}`
    );
  }

  listLandingImages(vipId: string | null, profileId: string | null) {
    return this.http.get<Result<LandingPicturesViewModel[]>>(
      `${environment.api}/public/landing/list/${vipId}/${profileId}`
    );
  }

  listenToProfile(vip: string | null, callback: Function) {
    let path = `${RESELLER.collection}/${RESELLER.linkroom}`;
    path += `/${VIP.collection}/${vip}`;
    path += `/${VIP_PROFILE.collection}`;

    const profileCollection = collectionGroup(this.firestore, VIP_PROFILE.collection);

    let data: { type: string; id: string; status: boolean }[] = [];

    onSnapshot(profileCollection, snapshot => {
      data = [];

      snapshot.docChanges().map(change => {
        if (change.type == 'modified')
          data.push({
            type: change.type,
            id: change.doc.id,
            status: change.doc.data()['status']
          });
      });
      callback(data);
    });
  }

  listenToEvent(vip: string | null, profile: string | null, event: string | null) {
    let path = `${RESELLER.collection}/${RESELLER.linkroom}`;
    path += `/${VIP.collection}/${vip}`;
    path += `/${VIP_PROFILE.collection}/${profile}`;
    path += `/${EVENT.collection}`;

    const eventCollection = collection(this.firestore, path);

    return collectionData(eventCollection, { idField: '_id' }).pipe(
      map(data =>
        data.map(x => {
          const event: VipEventViewModel = {
            _id: x._id,
            startDate: x['startDate'],
            status: x['status'],
            active: x['active'],
            locked: x['locked'],
            endDate: x['endDate']
          };

          return event;
        })
      ),
      map(data => data.filter(x => x.active && x.status != 0))
    );
  }
}
