import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { firstValueFrom, lastValueFrom } from 'rxjs';

import { PublicService } from '../../features/services/public.service';
import { QueueService } from 'src/app/features/services/queue.service';
import { EventUserStatus } from 'src/app/shared/utility/enums';

// This is done because for some reason, the guard doesn't allow two injects in the same function
@Injectable({ providedIn: 'root' })
class QueueGuardService {
  constructor(
    private router: Router,
    private queueService: QueueService,
    private publicService: PublicService
  ) {}

  list(vip: string | null, profile: string | null, event: string | null) {
    return this.queueService.list(vip, profile, event);
  }

  createUrlTree(commands: any[]) {
    return this.router.createUrlTree(commands);
  }

  getProfileUrl(profile: string | null) {
    return this.publicService.getProfile(profile);
  }
}

export const queueGuard: CanActivateFn = async (route, state) => {
  const getLocalStorageItem = (key: string) => localStorage.getItem(key);
  const guestId = getLocalStorageItem('guestId');
  const profileId = getLocalStorageItem('profileId');
  const eventId = getLocalStorageItem('eventId');
  const vipId = getLocalStorageItem('vipId');
  const paymentIntent = getLocalStorageItem('paymentIntent');

  const queueGuardService = inject(QueueGuardService);

  const profile = await lastValueFrom(queueGuardService.getProfileUrl(profileId));
  const redirectUrl = queueGuardService.createUrlTree([`/vip/${profile.data.profileUrl}`]);

  if (!guestId || !eventId) {
    return redirectUrl;
  }

  const guests = await firstValueFrom(
    queueGuardService.list(vipId, profileId, eventId)
  );

  if (guests.length < 1) {
    return redirectUrl;
  }

  const guest = guests.find(x => x._id === guestId);
  if (!guest) {
    return redirectUrl;
  }

  // const position = guests.findIndex(x => x._id === guestId) + 1;
  // const isPositionValid = position >= 1 && position <= profile.data.maxPeopleInPresentation;
  // const isGuestOngoing = guest.status === EventUserStatus.ONGOING;

  if ((guest.status === 0 || guest.status === 1) && (paymentIntent || profile.data.callPrice === 0)) {
    return true;
  }

  return redirectUrl;
};
