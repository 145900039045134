import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';

import { map } from 'rxjs';

import { GuestViewModel } from '../models/guest.model';
import { Result } from 'src/app/shared/models/result.model';
import { EventUserStatus } from 'src/app/shared/utility/enums';
import {
  EVENT,
  GUEST,
  QUEUE,
  RESELLER,
  VIP,
  VIP_PROFILE
} from 'src/app/shared/utility/global.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  private firestore: Firestore = inject(Firestore);

  constructor(private http: HttpClient) {}

  list(vip: string | null, profile: string | null, event: string | null) {
    let path = `${RESELLER.collection}/${RESELLER.linkroom}`;
    path += `/${VIP.collection}/${vip}`;
    path += `/${VIP_PROFILE.collection}/${profile}`;
    path += `/${EVENT.collection}/${event}`;
    path += `/${GUEST.collection}`;

    const guestCollection = collection(this.firestore, path);

    console.log(path);

    return collectionData(guestCollection, { idField: '_id' }).pipe(
      map(data =>
        data.map(x => {
          const guest: GuestViewModel = {
            _id: x._id,
            createdOn: x['createdOn'],
            customerId: x['customerId'],
            email: x['email'],
            refund: x['refund'],
            name: x['name'],
            paymentActive: x['paymentActive'],
            phone: x['phone'],
            question: x['question'],
            status: x['status']
          };

          console.log(guest);

          return guest;
        })
      ),
      map(data =>
        data.filter(
          x =>
            x.status !== EventUserStatus.DONE &&
            x.status !== EventUserStatus.REMOVED &&
            x.createdOn &&
            x.customerId
        )
      ),
      map(data => data.sort((a, b) => a.createdOn - b.createdOn))
    );
  }

  listForNextInQueue(vip: string | null, profile: string | null, event: string | null) {
    let path = `${RESELLER.collection}/${RESELLER.linkroom}`;
    path += `/${VIP.collection}/${vip}`;
    path += `/${VIP_PROFILE.collection}/${profile}`;
    path += `/${EVENT.collection}/${event}`;
    path += `/${GUEST.collection}`;

    const guestCollection = collection(this.firestore, path);

    return collectionData(guestCollection, { idField: '_id' }).pipe(
      map(data =>
        data.map(x => {
          const guest: GuestViewModel = {
            _id: x._id,
            createdOn: x['createdOn'],
            customerId: x['customerId'],
            email: x['email'],
            refund: x['refund'],
            name: x['name'],
            paymentActive: x['paymentActive'],
            phone: x['phone'],
            question: x['question'],
            status: x['status']
          };

          return guest;
        })
      ),
      map(data =>
        data.filter(
          x =>
            x.status !== EventUserStatus.DONE && x.status !== EventUserStatus.REMOVED && x.createdOn
        )
      ),
      map(data =>
        data.sort((a, b) => {
          if (a.customerId && !b.customerId) return -1;
          if (!a.customerId && b.customerId) return 1;

          return a.createdOn - b.createdOn;
        })
      )
    );
  }

  remove(vip: string | null, profile: string | null, event: string | null, guest: string | null) {
    return this.http.post<Result>(`${environment.api}${QUEUE.endpoint}/remove`, {
      vip,
      profile,
      event,
      guest
    });
  }
}
